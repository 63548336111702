import log from './log'
import pulse, { loadPulseIfNecessary } from './trackers/pulse'
import mbl from './trackers/mbl'
import getIdentityHelper from './identity-helper'
import delegateClickEvents from './delegate-click-events'
import { getConsentManager, hasConsentedToAll } from './consent-manager'

const vgSpecialTracking = async (metadata, opts) => {
    opts = opts || {}

    log('called')

    const { showSimplifiedLoginWidget } = getIdentityHelper()

    showSimplifiedLoginWidget({}, 'vg-special-continue-as')

    async function track(overrideOpts = {}) {
        const mergedOpts = { ...opts, ...overrideOpts }
        log('tracking', metadata, mergedOpts)

        const promises = []

        try {
            promises.push(
                pulse(
                    { ...metadata, articleId: mergedOpts.articleId || metadata.articleId },
                    {
                        promo: mergedOpts.promo,
                        paid: mergedOpts.paid,
                        originUrl: mergedOpts.originUrl,
                        trackLeave: mergedOpts.trackLeave,
                    },
                ),
            )
        } catch (err) {
            console.log(err)
        }

        try {
            promises.push(mbl())
        } catch (err) {
            console.log(err)
        }

        await Promise.all(promises)
    }

    if (opts.track === false) {
        // load pulse sdk (for e.g. salesposter etc) – but do not track or load tcf
        await loadPulseIfNecessary()
    } else {
        await track()
    }

    if (opts.clickListener) {
        log('enabling click listener')
        delegateClickEvents(metadata.articleId)
    }

    return { track }
}

vgSpecialTracking.getIdentityHelper = getIdentityHelper
vgSpecialTracking.consent = {
    manager: getConsentManager,
    hasConsentedToAll,
}

export default vgSpecialTracking
