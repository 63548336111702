export const renderAutoLoginMessage = () => {
    const wrapper = document.createElement('div')
    wrapper.style.display = 'contents'
    wrapper.innerHTML = `
        <style>
            .auto-login-message {
                background: rgba(6, 26, 87, 1);
                color: white;
                font-size: 14px;
                padding: 8px;
                font-family: Arial, sans-serif;
            }
            @media (max-width: 768px) {
                .auto-login-message {
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 1000;
                    padding: 12px;
                }
                .auto-login-message-content {
                    flex-direction: column;
                    gap: 16px;
                }
            }
            .auto-login-message-content {
                margin: 0 auto;
                display: flex;
                max-width: 1140px;
                justify-content: space-between;
                align-items: center;
                font-family: Arial, sans-serif;
            }
            .auto-login-message-text {
                max-width: 50%;
            }
            @media (max-width: 768px) {
                .auto-login-message-text {
                    max-width: 100%;
                }
            }
            .auto-login-message-content p {
                margin: 0;
                font-size: 14px;
                font-family: Arial, sans-serif;
            }
            .auto-login-message-content a {
                color: white;
                text-decoration: underline;
            }
            .auto-login-message-button {
                font-family: Arial, sans-serif;
                display: flex;
                height: 48px;
                padding: 0px 16px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 24px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 2px 4px 0px rgba(113, 117, 134, 0.45);
                color: rgba(6, 26, 87, 1);
                -webkit-appearance: none;
                appearance: none;
                border: none;
                cursor: pointer;
            }
        </style>
        <div class="auto-login-message">
            <div class="auto-login-message-content">
                <div class="auto-login-message-text">
                    <strong>Hei, du er nå logget inn med din Schibsted Media-konto!</strong>
                    <p>
                        Din Schibsted-konto kan brukes på alle Schibsteds tjenester.<br /><a href="https://payment.schibsted.no/profile-pages">Les mer om din Schibsted-konto</a>.
                    </p>
                </div>
                <button class="auto-login-message-button">OK, jeg forstår</button>
            </div>
        </div>
    `

    const insertPoint = document.querySelector('main') || document.body;
    insertPoint.insertAdjacentElement('afterbegin', wrapper)
    wrapper.querySelector('.auto-login-message-button').addEventListener(
        'click',
        () => {
            wrapper.remove()
        },
        { once: true },
    )
    setTimeout(() => {
        wrapper?.remove()
    }, 10000)
}
