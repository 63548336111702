// https://github.schibsted.io/vg/vgno/blob/bdc33f68daf13effc461ee2592a08db7c706688d/packages/utils/src/consents.ts
// https://github.schibsted.io/spt-privacy/sourcepoint

import addScript from './add-script'

export function hasConsentManager() {
    return '_tcf_' in window
}

export function loadConsentManagerIfNecessary() {
    if (!hasConsentManager()) {
        // see https://github.schibsted.io/vg/tcf-script-builder
        return addScript({
            src: 'https://tcf.vg.no/vg.js',
            async: true,
        })
    }
}

export async function getConsentManager() {
    return new Promise((resolve, reject) => {
        if (!('_tcf_' in window)) {
            document.addEventListener(
                'cmp:loaded',
                () => {
                    resolve(window._tcf_)
                },
                { once: true },
            )
        } else {
            resolve(window._tcf_)
        }
    })
}

export async function hasConsentedToAll() {
    const consentManager = await getConsentManager()
    let hasConsent = consentManager.getConsentedToAllSync()

    if ([true, false].includes(hasConsent)) {
        return hasConsent
    }

    return new Promise((resolve, reject) => {
        let resolved = false

        consentManager.isConsentedToAll(consent => {
            // avoid multiple calls to resolve?
            if (resolved) {
                console.log('consent changed', consent)
            } else {
                resolved = true
                resolve(consent)
            }
        })
    })
}
